import { lazy, Fragment } from "react";
import { Row, Col } from "antd";
import i18n from "i18next";
import { withTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";

import * as S from "./styles";

const SvgIcon = lazy(() => import("../../common/SvgIcon"));
const Container = lazy(() => import("../../common/Container"));

const Footer = ({ t }) => {
  const handleChange = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  const SocialLink = ({ href, src }) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  return (
    <Fragment>
      <Fade bottom>
        <S.Footer>
          <Container>
            <Row type="flex" justify="space-between">
              <Col lg={8} md={8} sm={12} xs={24}>
                <S.Title>{t("Policy")}</S.Title>
                <S.Large target="_blank" to={"//www.freeprivacypolicy.com/live/33508ef3-d9b5-4c3d-bbbd-2ee7b504f301"} left="true">
                  {t("Privacy Policy")}
                </S.Large>
                <S.Large target="_blank" left="true" to={"//www.freeprivacypolicy.com/live/5e0b4e43-b149-4545-8fd9-dc70c0117498"}>
                  {t("Terms & Conditions")}
                </S.Large>
              </Col>
        {/*      <Col lg={6} md={6} sm={12} xs={24}>*/}
        {/*        <S.Empty />*/}
        {/*        <S.Large left="true" to="/">*/}
        {/*          {t("Support Center")}*/}
        {/*        </S.Large>*/}
        {/*        <S.Large left="true" to="/">*/}
        {/*          {t("Customer Support")}*/}
        {/*        </S.Large>*/}
        {/*      </Col>*/}
            </Row>
        {/*    <Row type="flex" justify="space-between">*/}
        {/*      <Col lg={10} md={10} sm={12} xs={24}>*/}
        {/*        <S.Empty />*/}
        {/*        <S.Language>{t("ADDRESS")}</S.Language>*/}
        {/*        <S.Para>Rancho Santa Margarita</S.Para>*/}
        {/*        <S.Para>2131 Elk Street</S.Para>*/}
        {/*        <S.Para>California</S.Para>*/}
        {/*      </Col>*/}
        {/*      <Col lg={8} md={8} sm={12} xs={24}>*/}
        {/*        <S.Title>{t("Company")}</S.Title>*/}
        {/*        <S.Large left="true" to="/">*/}
        {/*          {t("About")}*/}
        {/*        </S.Large>*/}
        {/*        <S.Large left="true" to="/">*/}
        {/*          {t("Blog")}*/}
        {/*        </S.Large>*/}
        {/*        <S.Large left="true" to="/">*/}
        {/*          {t("Press")}*/}
        {/*        </S.Large>*/}
        {/*        <S.Large left="true" to="/">*/}
        {/*          {t("Careers & Culture")}*/}
        {/*        </S.Large>*/}
        {/*      </Col>*/}
        {/*      <Col lg={6} md={6} sm={12} xs={24}>*/}
        {/*        <S.Select>*/}
        {/*          <S.Label htmlFor="select-lang">{t("Language")}</S.Label>*/}
        {/*          <S.LangSelect*/}
        {/*            onChange={handleChange}*/}
        {/*            value={i18n.language}*/}
        {/*            id="select-lang"*/}
        {/*          >*/}
        {/*            <option value="en">English</option>*/}
        {/*            <option value="es">Español</option>*/}
        {/*          </S.LangSelect>*/}
        {/*        </S.Select>*/}
        {/*      </Col>*/}
        {/*    </Row>*/}
          </Container>
        </S.Footer>
        <S.Extra>
          <Container border="true">
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              style={{ paddingTop: "3rem" }}
            >
              <S.NavLink to="/">
                <S.LogoContainer>
                  <SvgIcon
                    src="logo.svg"
                    aria-label="homepage"
                    width="128px"
                    height="128px"
                  />
                </S.LogoContainer>
              </S.NavLink>
              {/*<S.FooterContainer>*/}
              {/*  <SocialLink*/}
              {/*    href="https://github.com/Adrinlol/create-react-app-adrinlol"*/}
              {/*    src="github.svg"*/}
              {/*  />*/}
              {/*  <SocialLink*/}
              {/*    href="https://twitter.com/Adrinlolx"*/}
              {/*    src="twitter.svg"*/}
              {/*  />*/}
              {/*  <SocialLink*/}
              {/*    href="https://www.linkedin.com/in/lasha-kakabadze/"*/}
              {/*    src="linkedin.svg"*/}
              {/*  />*/}
              {/*  <SocialLink*/}
              {/*    href="https://github.com/Adrinlol/"*/}
              {/*    src="instagram.svg"*/}
              {/*  />*/}
              {/*  <SocialLink*/}
              {/*    href="https://medium.com/@lashakakabadze/"*/}
              {/*    src="medium.svg"*/}
              {/*  />*/}
              {/*</S.FooterContainer>*/}
            </Row>
          </Container>
        </S.Extra>
      </Fade>
    </Fragment>
  );
};

export default withTranslation()(Footer);
