import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import "antd/dist/antd.css";
import Amplify  from 'aws-amplify';
import ReactGA from 'react-ga';
import awsmobile from './aws-exports'
import { createBrowserHistory } from 'history';
import LinkedInTag from 'react-linkedin-insight';


import Router from "./router";
import i18n from "./translation";
import * as serviceWorker from "./serviceWorker";

const history = createBrowserHistory();




LinkedInTag.init("3162161", 'dc', false);
ReactGA.initialize("UA-197064169-2")
Amplify.configure(awsmobile);

history.listen(location => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
});




const App = () => (
  <BrowserRouter>
    <I18nextProvider i18n={i18n}>
      <Router history={history} />
    </I18nextProvider>
  </BrowserRouter>
);

ReactDOM.render(<App />, document.getElementById("root"));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
